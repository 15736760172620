export enum PaymentProcessorKind {
  DLOCAL = 'dlocal',
  STRIPE = 'stripe',
  NONE = 'none',
  THIRD = 'third'
}

export enum ECountries {
  AR = 'AR',
  BR = 'BR',
  CL = 'CL',
  CO = 'CO',
  MX = 'MX',
  PE = 'PE',
  UY = 'UY',
  CA = 'CA',
  PA = 'PA',
  BO = 'BO',
  CR = 'CR',
  PY = 'PY',
  US = 'US',
  INT = 'INT'
}

export enum EDateFormat {
  MDY = 'MM/dd/yyyy',
  DMY = 'dd/MM/yyyy',
  YMD = 'yyyy/MM/dd'
}

export enum ECountryDateFormat {
  CA = 'MDY',
  US = 'MDY',
  INT = 'DMY'
}

export enum ELocales {
  esAR = 'es-AR',
  esCO = 'es-CO',
  esMX = 'es-MX',
  esUY = 'es-UY',
  esPE = 'es-PE',
  esCL = 'es-CL',
  ptBR = 'pt-BR',
  enCA = 'en-CA',
  esPA = 'es-PA',
  esBO = 'es-BO',
  esCR = 'es-CR',
  esPY = 'es-PY',
  enUS = 'en-US',
}

export declare enum EPersonalIdType {
  DNI = 'DNI',
  CURP = 'CURP',
  CI = 'CI',
  CC = 'CC',
  CNPJ = 'CNPJ',
  CUIT = 'CUIT',
  CPF = 'CPF',
  RUT = 'RUT'
}

export enum EBillingType {
  OTHER = 'other',
  STUDENT = 'student',
  BUSINESS = 'business'
}

export enum FeatureFlags {
  DLOCAL_MOCK = 'x-acamica-force-dlocal-mock',
  SUBMIT_ORDER_MOCK = 'x-acamica-force-submit-mock',
  CREATE_ORDER_MOCK = 'x-acamica-force-create-mock'
}

export enum EWeekDays {
  MON_WED = 'm/w',
  TUE_THU = 't/t'
}

export enum EPaymentMethodType {
  CREDIT = 'credit',
  DEBIT = 'debit',
  TRANSFER = 'transfer',
  TICKET = 'ticket',
  NOCHARGE = 'nocharge',
  THIRD = 'third'
}

export enum EBusinessUnit {
  career = 'career',
  course = 'course',
  'on_demand' = 'on_demand'
}

export enum EClusterNameKey {
  ctdParttime = 'Certified Tech Developer PT',
  ctdFulltime = 'Certified Tech Developer FT',
  ctdBackend = 'CTD Backend',
  ctdFrontend = 'CTD Frontend',
  fullstack = 'Programación Web Full Stack',
  dataScience = 'Data Science',
  dataAnalytics = 'Data Analytics',
  settleDebts = 'Settle debts',
  onDemand = 'On Demand'
}

export enum EClusterDescriptionKey {
  ctdDefault = 'cluster_ctd_default_description',
  ctdBackend = 'cluster_ctd_backend_description',
  ctdFrontend = 'cluster_ctd_frontend_description',
  fullstackDefault = 'cluster_fullstack_default_description',
  dataScienceDefault = 'cluster_ds_default_description',
  dataAnalyticsDefault = 'cluster_da_default_description',
  settleDebtsDefault = 'cluster_sd_default_description',
  onDemandDefault = 'cluster_on_demand_default_description',
  onDemandFront = 'cluster_on_demand_frontend_description',
  onDemandBack = 'cluster_on_demand_backend_description',
}

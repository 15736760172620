import styled from 'styled-components';

interface StyleProps {
  theme?: string;
}

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px;

  /* Desktop */
  @media screen and (min-width: 1200px) {
    margin: 0 0 60px;
  }
`;

export const Logo = styled.img`
  z-index: 10;
  width: 195px;
  height: 15px;
`;

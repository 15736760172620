import { ECountries, ELocales } from 'model/enums';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
// Countries with custom offers
//const supportedCountries = ['AR', 'CO', 'MX', 'CL', 'PE', 'UY', 'US'];

const localeToCountryMap = {
  [ELocales.esAR]: ECountries.AR,
  [ELocales.esCO]: ECountries.CO,
  [ELocales.esMX]: ECountries.MX,
  [ELocales.esUY]: ECountries.UY,
  [ELocales.esPE]: ECountries.PE,
  [ELocales.esCL]: ECountries.CL,
  [ELocales.ptBR]: ECountries.BR,
  [ELocales.esPA]: ECountries.PA,
  [ELocales.esBO]: ECountries.BO,
  [ELocales.esCR]: ECountries.CR,
  [ELocales.esPY]: ECountries.PY,
  [ELocales.enUS]: ECountries.US,
};

const useCountryCode = (): [string] => {
  const router = useRouter();
  const country = useMemo(() => {
    const { locale: currentLocale, defaultLocale } = router;
    const _country =
      localeToCountryMap[currentLocale || (defaultLocale as string)];
    return _country;
  }, [router.locale, router.defaultLocale]);

  return [country];
};

export default useCountryCode;

import styled, { css } from 'styled-components';

interface StyleProps {
  icon?: boolean;
  current?: boolean;
  disabled?: boolean;
}

export const Container = styled.nav`
  font-size: 16px;
  padding-top: 6px;

  /* Mobile */
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

export const Item = styled.li`
  display: inline-block;
  font-weight: bold;
  vertical-align: middle;
  color: var(--dh-gray8);
  height: 24px;
  line-height: 24px;
  a {
    font-weight: bold;
  }
  a,svg {
    color: var(--dh-gray8);
    transition-property: color;
    transition: var(--hoverTransition);
    cursor: pointer;

    &:hover {
      color: var(--dh-black2);
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      text-decoration: underline;
      text-underline-offset: 2px;
      text-decoration-thickness: 2px;
    }
  }

  &:last-child {
    padding-right: 0;
  }

  ${({ current }: StyleProps) =>
    current &&
    css`
      & a,
      svg {
        color: var(--dh-black);
      }
    `}

  ${({ disabled }: StyleProps) =>
    disabled &&
    css`
      & a,
      svg {
        pointer-events: none;
        opacity: 0.6;
      }
    `}

    ${({ icon }: StyleProps) =>
    !icon &&
    css`
      padding: 0 16px;
    `}
`;

import { gql } from '@apollo/client';
import { IOffer, IProduct, IProductGroup } from 'model/interfaces';

export const GET_ALL_PRODUCTS = gql`
  query getAllProducts {
    getAllProducts {
      id
      name
    }
  }
`;

export const GET_PRODUCT_AND_OFFER_QUICK = gql`
  query getProductAndOfferQuick($id: ID!, $token: String, $country: String!) {
    getProductOfferAndPlans(productID: $id, country: $country, token: $token) {
      ware {
        id
        productId
        name
        description
        verticalAlias
        verticalId
        vertical
        businessUnit
        businessUnitAlias
        businessUnitId
        clusterAlias
        onlyConfiguration
      }
      offer {
        id
        token
        maxPurchases
        plans: includedPlans {
          id
          installmentCount: installment
          installmentAmount
          enrollmentAmount
          remarkedEnrollmentAmount
          remarkedInstallmentAmount
          programAmount
          currency
          partialAmount
          billingRange
          methods: includedMethods {
            id
            name
            type: literalType
            proccessor
            description
            typeID
            planMethodDescription
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_AND_OFFER = gql`
  query getProductAndOffer($id: ID!, $token: String, $country: String) {
    getOffer(token: $token, productID: $id, country: $country) {
      id
      token
      maxPurchases
      plans(productID: $id, country: $country) {
        id
        installmentCount: installment
        installmentAmount
        enrollmentAmount
        remarkedEnrollmentAmount
        remarkedInstallmentAmount
        programAmount
        currency
        discount
        enrollmentDiscount
        partialAmount
        billingRange
        methods(productID: $id) {
          id
          name
          type: literalType
          proccessor
          description
          typeID
          planMethodDescription
        }
      }
    }
    getProduct(productID: $id) {
      id
      productId
      name
      description
      verticalAlias
      verticalId
      vertical
      businessUnit
      businessUnitAlias
      businessUnitId
      clusterAlias
      onlyConfiguration
      clusterId
      isSubscription
      instantCharge
      freemiumSkipDays
      defaultOffer {
        id
        maxPurchases
        token
        plans(productID: $id) {
          id
          installmentCount: installment
          installmentAmount
          enrollmentAmount
          remarkedEnrollmentAmount
          remarkedInstallmentAmount
          programAmount
          currency
          discount
          partialAmount
          billingRange
          methods(productID: $id) {
            id
            name
            type: literalType
            proccessor
            description
            typeID
            planMethodDescription
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query getProduct($id: ID!) {
    getProduct(productID: $id) {
      id
      productId
      name
      description
      verticalAlias
      verticalId
      vertical
      businessUnit
      businessUnitAlias
      businessUnitId
      clusterAlias
      onlyConfiguration
      paymentTitle
      paymentDisclaimer
      shouldAcceptTyC
      disabledInvoice
      disabledRecurring
      thanksMessage
      clusterId
      isSubscription
      instantCharge
      defaultOffer {
        id
        maxPurchases
        token
        plans(productID: $id) {
          id
          installmentCount: installment
          installmentAmount
          enrollmentAmount
          remarkedEnrollmentAmount
          remarkedInstallmentAmount
          programAmount
          currency
          discount
          enrollmentDiscount
          partialAmount
          billingRange
          methods(productID: $id) {
            id
            name
            type: literalType
            proccessor
            description
            typeID
            planMethodDescription
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_EVENTS = gql`
  query getProductGroups($id: ID!, $country: String) {
    getAllEvents(productID: $id, country: $country) {
      id
      code
      startsAt
      endDate
      quota
      externalAdmissionId
      admissionUrl
      slotFrom: slotFromGMT0
      slotTo: slotToGMT0
      soldout
    }
  }
`;

export const GET_EVENT = gql`
  query getEvent($commissionName: String, $productID: Int, $country: String) {
    getEvent(
      commissionName: $commissionName
      productID: $productID
      country: $country
    ) {
      id
      code
      duration {
        name
      }
      startsAt
      endDate
      quota
      admissionUrl
      slotFrom: slotFromGMT0
      slotTo: slotToGMT0
      soldout
    }
  }
`;

export const GET_OFFER = gql`
  query getOffer($token: String, $id: ID!, $country: String) {
    getOffer(token: $token, productID: $id, country: $country) {
      id
      token
      maxPurchases
      plans(productID: $id, country: $country) {
        id
        installmentCount: installment
        installmentAmount
        enrollmentAmount
        remarkedEnrollmentAmount
        remarkedInstallmentAmount
        programAmount
        currency
        discount
        enrollmentDiscount
        partialAmount
        billingRange
        methods(productID: $id) {
          id
          name
          type: literalType
          proccessor
          description
          typeID
          planMethodDescription
        }
      }
    }
  }
`;

export interface GetEventData {
  getEvent: IProductGroup;
}
export interface GetEventVars {
  commissionName: string;
  productID: number;
  country: string;
}

export interface GetProductVars {
  id: string;
  country: string;
}

export interface GetProductEventsData {
  getAllEvents: IProductGroup[];
}

export interface GetProductData {
  getProduct: IProduct;
}

export interface GetProductAndOfferData {
  getProduct: IProduct;
  getOffer: IOffer | null;
}

export interface GetProductAndOfferDataQuick {
  getProductOfferAndPlans: { ware: IProduct; offer: IOffer };
}

export interface GetOfferVars {
  token: string;
  id: string;
  country: string;
}

export interface GetOfferData {
  getOffer: IOffer;
}

import { ECountries, ELocales } from './enums';

export const CARD_VERIFICATION_AMOUNT = {
  [ECountries.AR]: '0',
  [ECountries.BR]: '0',
  [ECountries.CO]: '100',
  [ECountries.CL]: '0',
  [ECountries.MX]: '0',
  [ECountries.UY]: '0'
};

export const FAQ_URL = {
  [ECountries.AR]: 'https://www.digitalhouse.com/ar/acciones/certified-tech-developer-preguntas-frecuentes',
  [ECountries.BR]: 'https://www.digitalhouse.com/br/perguntas-frequentes-ctd',
  [ECountries.CO]: 'https://www.digitalhouse.com/latam/acciones/certified-tech-developer-preguntas-frecuentes',
  [ECountries.CL]: 'https://www.digitalhouse.com/co/landing/300becas-ctd-preguntasfrecuentes-ch',
  [ECountries.INT]: 'https://www.digitalhouse.com/ar/acciones/certified-tech-developer-preguntas-frecuentes',
};

export const FAQ_INTENSIVE_URL = {
  [ECountries.AR]: 'https://www.digitalhouse.com/ar/preguntas-frecuentes',
  [ECountries.BR]: 'https://www.digitalhouse.com/br/perguntas-frequentes',
  [ECountries.CO]: 'https://www.digitalhouse.com/ar/preguntas-frecuentes',
  [ECountries.CL]: 'https://www.digitalhouse.com/ar/preguntas-frecuentes',
  [ECountries.INT]: 'https://www.digitalhouse.com/ar/preguntas-frecuentes',
};

export const FAQ_ON_DEMAND_URL = 'https://www.digitalhouse.com/preguntas-frecuentes-certificaciones'

export const TERMS_DISCLAIMER_URL = {
  [ECountries.AR]: 'https://www.digitalhouse.com/ar/terminos-condiciones',
  [ECountries.BR]: 'https://www.digitalhouse.com/br/termos-e-condicoes',
  [ECountries.CO]: 'https://www.digitalhouse.com/co/terminos-condiciones',
  [ECountries.CL]: 'https://www.digitalhouse.com/co/landing/300becas-ctd-tyc-ch',
  [ECountries.MX]: 'https://www.digitalhouse.com/mx/terminos-condiciones',
  [ECountries.INT]: 'https://www.digitalhouse.com/terminos-condiciones',
};

export const TERMS_DISCLAIMER_INTENSIVE_URL = {
  [ECountries.AR]: 'https://www.digitalhouse.com/ar/terminos-condiciones',
  [ECountries.BR]: 'https://www.digitalhouse.com/br/termos-e-condicoes',
  [ECountries.CO]: 'https://www.digitalhouse.com/co/terminos-condiciones',
  [ECountries.CL]: 'https://www.digitalhouse.com/ar/terminos-condiciones',
  [ECountries.MX]: 'https://www.digitalhouse.com/mx/terminos-condiciones',
  [ECountries.INT]: 'https://www.digitalhouse.com/terminos-condiciones',
};

export const TERMS_DISCLAIMER_ON_DEMAND_URL = {
  [ECountries.AR]: 'https://www.digitalhouse.com/ar/tyc-reglamento-ondemand',
  [ECountries.CO]: 'https://www.digitalhouse.com/co/tyc-reglamento-ondemand',
  [ECountries.MX]: 'https://www.digitalhouse.com/mx/tyc-reglamento-ondemand',
  [ECountries.INT]: 'https://www.digitalhouse.com/tyc-reglamento-ondemand',
};

export const PRIVACY_POLICY_URL = {
  [ECountries.AR]: 'https://www.digitalhouse.com/ar/politica-de-privacidad',
  [ECountries.BR]: 'https://www.digitalhouse.com/br/politica-de-privacidade',
  [ECountries.CO]: 'https://www.digitalhouse.com/co/politica-de-privacidad',
  [ECountries.CL]: 'https://www.digitalhouse.com/co/landing/300becas-ctd-politicasdeprivacidad-ch',
  [ECountries.MX]: 'https://www.digitalhouse.com/mx/politica-de-privacidad',
  [ECountries.INT]: 'https://www.digitalhouse.com/politica-de-privacidad',
};

export const PRIVACY_POLICY_INTENSIVE_URL = {
  [ECountries.AR]: 'https://www.digitalhouse.com/ar/politica-de-privacidad',
  [ECountries.BR]: 'https://www.digitalhouse.com/br/politica-de-privacidade',
  [ECountries.CO]: 'https://www.digitalhouse.com/co/politica-de-privacidad',
  [ECountries.CL]: 'https://www.digitalhouse.com/ar/politica-de-privacidad',
  [ECountries.MX]: 'https://www.digitalhouse.com/mx/politica-de-privacidad',
  [ECountries.INT]: 'https://www.digitalhouse.com/politica-de-privacidad',
};

export const PRIVACY_POLICY_ON_DEMAND_URL = {
  [ECountries.AR]: 'https://www.digitalhouse.com/ar/politica-de-privacidad',
  [ECountries.CO]: 'https://www.digitalhouse.com/co/politica-de-privacidad',
  [ECountries.MX]: 'https://www.digitalhouse.com/mx/politica-de-privacidad',
  [ECountries.INT]: 'https://www.digitalhouse.com/politica-de-privacidad',
};

export const REGULATION_URL = {
  [ECountries.AR]: 'https://www.digitalhouse.com/ar/reglamento-ctd-ar',
  [ECountries.BR]: '',
  [ECountries.CO]: 'https://www.digitalhouse.com/co/reglamento-ctd-co',
  [ECountries.CL]: 'https://www.digitalhouse.com/reglamento-ctd-la',
  [ECountries.MX]: 'https://www.digitalhouse.com/mx/reglamento',
  [ECountries.INT]: 'https://www.digitalhouse.com/reglamento'
};

export const REGULATION_INTENSIVE_URL = {
  [ECountries.AR]: 'https://www.digitalhouse.com/ar/reglamento',
  [ECountries.BR]: '',
  [ECountries.CO]: 'https://www.digitalhouse.com/co/reglamento-para-cursos',
  [ECountries.CL]: 'https://www.digitalhouse.com/reglamento',
  [ECountries.MX]: 'https://www.digitalhouse.com/mx/reglamento',
  [ECountries.INT]: 'https://www.digitalhouse.com/reglamento'
};

export const DEFAULT_LOCALE = ELocales.esAR;

export const INSTANT_PAYMENT = process.env.NEXT_PUBLIC_INSTANT_PAYMENT === 'true';

export const SEGMENT_KEY = process.env.NEXT_PUBLIC_SEGMENT_KEY;
export const SEGMENT_CDN = process.env.NEXT_PUBLIC_SEGMENT_CDN;

export const paths = {
  checkoutGroup: '/products/[id]/group',
  checkoutPayment: '/products/[id]/payment',
  checkoutReview: '/products/[id]/review',
  checkoutConfirm: '/products/[id]/confirm',
  finantialPayment: "/ware/[id]/u/[userId]/payment",
  finantialReview: "/ware/[id]/u/[userId]/review",
  finantialConfirm: "/ware/[id]/u/[userId]/confirm",
  serverError: '/500',
  error: '/404'
} as const; type ObjectValues<T> = T[keyof T]; export type StatusEnum = ObjectValues<typeof paths>;

import React, { FunctionComponent } from 'react';
import { ChevronRight } from 'react-feather';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Container, Item } from './Breadcrumbs.styled';
import { useTranslation } from 'next-i18next';

const SlimBreadcrumbs: FunctionComponent = () => {
  const router = useRouter();
  const { t } = useTranslation('main');

  const links = [
    {
      path: '/ware/[id]/u/[userId]/payment',
      text: t('payment.options')
    },
    {
      path: '/ware/[id]/u/[userId]/review',
      text: t('review.confirm')
    }
  ];

  const isMinorCurrentPath = (i: number) => i <= getCurrentPath();

  const getCurrentPath = () => {
    for (const [i, link] of links.entries()) {
      if (router.pathname === link.path) {
        return i;
      }
    }
    return false;
  };

  return (
    <Container>
      <ul>
        {links.map((link, i) => (
          <React.Fragment key={i}>
            {i > 0 && (
              <Item icon current={isMinorCurrentPath(i - 1)}>
                <ChevronRight />
              </Item>
            )}
            <Item
              current={isMinorCurrentPath(i)}
              disabled={!isMinorCurrentPath(i)}
            >
              <Link
                shallow
                href={{
                  pathname:
                    !isMinorCurrentPath(i) !== false
                      ? router.pathname
                      : links[i].path,
                  query: router.query
                }}
              >
                <a tabIndex={!isMinorCurrentPath(i) ? -1 : 0}>{link.text}</a>
              </Link>
            </Item>
          </React.Fragment>
        ))}
      </ul>
    </Container>
  );
};

export default SlimBreadcrumbs;

import React, { FunctionComponent } from 'react';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import SlimBreadcrumb from 'components/Breadcrumbs/SlimBreadcrumbs';
import { Container, Logo } from './Header.styled';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

interface Props {
  showBreadcrumbs?: boolean;
  theme?: 'light' | 'dark';
}

const Header: FunctionComponent<Props> = ({ showBreadcrumbs, theme }) => {
  const LOGO_PATH = theme
    ? theme === 'light'
      ? '/logo-light.svg'
      : '/logo-dark.svg'
    : '/logo.svg';

  const { t } = useTranslation('main');

  const router = useRouter()
  const regEx = /products/
  const isSlimPayment = !regEx.test(router.pathname);

  return (
    <Container>
      <Logo theme={theme} alt={t('group.logo')} src={`/img/${LOGO_PATH}`} />
      {
        showBreadcrumbs && (
          isSlimPayment ? <SlimBreadcrumb /> : <Breadcrumbs />
        )
      }     
    </Container>
  );
};

export default Header;
